import React from "react"
import Fade from "react-reveal/Fade"

function ServiceItem({ item1, item2, item3, src }) {
  return (
    <Fade bottom>
      <div className="col-lg-3 my-4 col-sm-12 text-center">
        <img src={src} width="128px" alt={item1} />
        <p>
          {item1}<br />
          {item2}<br />
          {item3}
        </p>
      </div>
    </Fade>
  )
}

export default ServiceItem
