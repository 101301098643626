import React from "react"
import ServiceItem from "../components/ServiceItem"
import { useStaticQuery, graphql } from "gatsby"

function Services() {
  const { allServicesYaml } = useStaticQuery(graphql`
    {
      allServicesYaml {
        edges {
          node {
            item1
            item2
            item3
            src
          }
        }
      }
    }
  `)
  return (
    <div id = "services" className="container-fluid" style={{ backgroundColor: "#080808", color: "white" }}>
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Skills</h1>
          <div className="row">
            {allServicesYaml.edges.map(({ node, index }) => {
              return <ServiceItem key={index} item1={node.item1} item2={node.item2} item3={node.item3} src={node.src} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services
