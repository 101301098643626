import React from "react"
import { SocialIcon } from "react-social-icons"
import Fade from "react-reveal/Fade"

function TeamItem({ src, title, description, link }) {
  return (
    <Fade bottom>
      <div className="col-lg-4 my-4 col-sm-12">
        <a href={link}>
        <img src={src} className="img-fluid rounded" width="500px" alt={title} />
        </a>
        <h3 className="mt-5">{title}</h3>
        <p>
          {description}
        </p>
        <SocialIcon className="mr-4" url={"http://github.com/bradferd/" + title} />
      </div>
    </Fade>
  )
}

export default TeamItem
