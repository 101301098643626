import React from 'react'
import { SocialIcon } from 'react-social-icons'
import picture from '../images/avatar.png'
import Fade from 'react-reveal/Fade'

function About() {
    return(
        <Fade right>
            <div id = "about" className="container-fluid" style={{ backgroundColor: "#F8F8F8" }}>
                <div className="container py-5">
                    <h1 className="text-center">About</h1>
                    <div className='text-center'>
                        <img className='image-fluid rounded-circle mx-auto d-block mb-5' src={picture} alt="Bradford Bailey" />
                        <p>
                            A software developer with proven experience thriving in a fast-paced environment, 
                            quickly making informed decisions, and balancing tasks efficiently. Strong focus on 
                            collaborating with new and existing team members to ensure best practices. Known for 
                            having a flexible attitude and a can-do mindset to overcome tasks.
                        </p>
                        <SocialIcon className="mr-4" url="http://github.com/bradferd/" />
                        <SocialIcon className="mr-4" url="http://linkedin.com/in/bradford-c-bailey" />
                        <SocialIcon className="mr-4" url="http://twitter.com/bradferd_b" />
                    </div>
                </div>
            </div>
        </Fade>
    )
}

export default About