import React from "react"
import Fade from "react-reveal/Fade"

export default function End() {
  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "#080808", color: "white" }}
    >
      <form name='contact' action='/thank-you' method='POST' data-netlify='true' data-netlify-honeypot='bot-field' className="container">
        <input type='hidden' name='bot-field' />
        <input type="hidden" name="form-name" value="contact" />
        <div className="flexbox">
          <Fade left>
            <h1 className="text-center my-5">Stay in touch</h1>
            <div className="row my-4">
              <div className="col-lg-6 mb-4">
                <input
                  name='name'
                  type="name"
                  className="inputBox"
                  placeholder="Your Name"
                />
              </div>
              <div className="col-lg-6 mb-4">
                <input
                  name='email'
                  type="email"
                  className="inputBox"
                  placeholder="Your Email"
                />
              </div>
            </div>
            <textarea name='message' rows = "2" className="inputBox mb-4" placeholder="Your Message" />
            <button type='submit' className="btn btn-light mb-4 py-3">Submit</button>
          </Fade>
        </div>
      </form>
    </div>
  )
}
